//
// Custom form styles
// --------------------------------------------------
$input-font-weight: inherit;
$input-font-size: $base-text-size;
$input-line-height: 24px;
$input-padding: ($input-height-base - $input-line-height) / 2 17px;

.rd-mailform {
  position: relative;
}

label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
  color: $gray;
}

.lt-ie-10,
.ie-10,
.ie-11 {
  input {
    padding-top: ($input-height-base - $input-line-height) / 2 - 1px;
    padding-bottom: ($input-height-base - $input-line-height) / 2 + 1px;
  }
}

input[type='password'] {
  font-size: 16px;
}

// Common form controls
// --------------------------------------------------

.input-sm,
.input-lg,
.form-control {
  font-size: $input-font-size;

  &, &:focus {
    box-shadow: none;
  }
}

textarea.form-control {
  height: $textarea-default-height;
  min-height: $textarea-default-min-height;
  max-height: $textarea-default-max-height;
  resize: vertical;
}

.form-control {
  -webkit-appearance: none;
  padding: $input-padding;
  line-height: $input-line-height;
  font-size: $font-size-base;

  &:focus {
    outline: 0;
  }
}

// Form groups
// -------------------------

.form-group {
  position: relative;
  margin-bottom: 0;
}

.form-group + .form-group {
  margin-top: 19px;
}

@media (min-width: $screen-md-min) {
  .form-group + .form-group {
    margin-top: 26px;
  }
}

// Form labels
// -------------------------

.form-label {
  position: absolute;
  top: 26px;
  left: 17px;
  font-size: $input-font-size;
  color: $input-color-placeholder;
  pointer-events: none;
  z-index: 9;
  transition: .3s;
  @include transform(translateY(-50%));

  &.focus {
    opacity: 0;
  }

  &.auto-fill {
    color: $input-color;
  }
}

.form-label-outside {
  @media (min-width: $screen-sm-min) {
    position: static;
    &, &.focus, &.auto-fill {
      @include transform(none);
      color: $text-color;
      font-size: $input-font-size;
    }
  }
}

// Form bse
// --------------------------------------------------
.rd-mailform {

  label {
    line-height: $input-line-height;
  }

  label + * {
    margin-top: 5px;
  }
}

.rd-mailform.form-white {
  input, textarea {
    background: $white;
  }
}

.rd-mailform.form-white-1 {
  input, textarea {
    background: $white;
    border: 1px solid $alto;
    border-radius: 3px;
  }

  label {
    font-size: 15px;
    font-weight: 400;
  }

  textarea {
    height: 170px;
  }
}

// Form validation
// -------------------------
.form-validation {
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 11px;
  line-height: 11px;
  color: $brand-danger;
  margin-top: 2px;
  transition: .3s;
}

// Error Styling
//
.has-error {
  @include form-control-validation-custom($state-danger-text, $state-danger-bg, $state-danger-bg);
}

// Success Styling
//
.has-success {
  @include form-control-validation-custom($state-success-text, $state-success-bg, $state-success-bg);
}

// Warning Styling
//
.has-warning {
  @include form-control-validation-custom($state-warning-text, $state-warning-bg, $state-warning-bg);
}

// Info Styling
//
.has-info {
  @include form-control-validation-custom($state-info-text, $state-info-bg, $state-info-bg);
}

// Form output
// -------------------------

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  visibility: hidden;
  @include transform(translateX(-500px));
  transition: .3s all ease;
  z-index: 9999999;

  &.active {
    @include transform(translateX(0));
    visibility: visible;
  }

  @media (min-width: $screen-xs) {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 2px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.error {
    color: $brand-danger;
  }
  &.success {
    color: $brand-success;
  }
}

// Radio and Checkbox Custom
// -------------------------

// Base Styles
//
.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;

  &, &-dummy {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -20px;
    margin-top: 5px;
    outline: none;
    cursor: pointer;
  }

  &-dummy {
    pointer-events: none;
    background: $gray-lighter;
    box-shadow: inset $shadow-area-xxs;

    &:after {
      position: absolute;
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}

// Custom Radio Styles
//
.radio,
.radio-inline {
  .radio-custom-dummy {
    border-radius: 50%;

    &:after {
      content: '';
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      background: $gray-darker;
      border-radius: 50%;
    }
  }
}

// Custom Checkbox Styles
//
.checkbox,
.checkbox-inline {
  padding-left: 20px;
  .checkbox-custom-dummy {
    pointer-events: none;
    border-radius: $border-radius-small;
    margin-left: 0;
    left: 0;

    &:after {
      content: '\e5ca';
      font-family: 'Material Icons';
      font-size: 20px;
      line-height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      color: $gray-darker;
    }
  }
}

// Variants
.page .rd-mailform-inline {
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(nowrap);
  @include align-items(stretch);
  margin: 6px auto 0;

  *,
  label,
  .btn {
    margin: 0;
  }

  label {
    top: 24px;
    left: 17px;
  }

  input {
    padding: 10px 17px;
  }

  .form-control {
    height: 47px;
  }

  .btn {
    font-weight: 400;
  }

  > *:first-child {
    @include flex-grow(1);
    &, * {
      border-radius: 5px 0 0 5px;
    }
  }

  > *:last-child {
    border-radius: 0 5px 5px 0;
  }
  @media (min-width: $screen-sm-min) {
    margin-left: 0;
  }
}

.rd-mailform-inline-variant-1 {
  label + input {
    margin-top: 0;
  }

  * + .btn {
    margin-top: 22px;
  }

  input {
    padding-left: 27px;
    padding-right: 27px;
  }

  label {
    left: 27px;
  }

  @media (min-width: $screen-xs-min) {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(stretch);
    .form-group {
      @include flex-grow(1);
    }

    * + .btn {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  .btn-sm {
    padding-left: 27px;
    padding-right: 27px;
  }
}

.rd-mailform-label-outside {
  .form-validation {
    top: 10px;
    right: 5px;
  }
}

.form-calculator {
  label + input {
    margin-top: 5px;
  }

  [class*='group'] + [class*='group'] {
    margin-top: 20px;
  }

  .total {
    input {
      border-bottom: 2px solid $curious-blue;
    }
  }
}

.form-wrap-recaptcha {
  .form-validation {
    top: 3px;
    left: 15px;
    right: auto;
  }
}