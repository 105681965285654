/*
* @subsection   RD Navbar
*
* @description  Describes style declarations for RD Navbar extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
*/
@import "rd-navbar_includes/_rd-navbar-mixins.scss";

/*
* @subsection General Styles
*/

// RD Navbar Basics
%rd-navbar-transition {
  transition: .33s all cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

%rd-navbar-hidden {
  display: none;
}

%rd-navbar-visible {
  display: block;
}

.rd-navbar--no-transition {
  &, * {
    transition: none !important;
  }
}

.rd-navbar-wrap {
  position: relative;
  z-index: 10;
  @extend %rd-navbar-transition;
}

.rd-navbar, .rd-navbar.rd-navbar--is-clone {
  @extend %rd-navbar-hidden;
}

.rd-navbar-fixed,
.rd-navbar-static,
.rd-navbar-fullwidth,
.rd-navbar-sidebar {
  @extend %rd-navbar-visible;
}

.rd-navbar-wrap,
.rd-navbar,
.rd-navbar-brand,
.rd-navbar-slogan,
.rd-navbar-dropdown,
.rd-navbar-megamenu,
.rd-navbar-collapse-items,
.brand-name,
.rd-navbar-nav,
.rd-navbar-panel,
.rd-navbar-search-form-input,
.rd-navbar-search-form-submit,
.rd-navbar-search-toggle,
.rd-navbar-live-search-results,
.rd-navbar-search-form {
  transition: .33s all ease-out;
}

// RD Navbar Collapse
.rd-navbar-collapse {
  &-toggle {
    @include make-toggle(
            $rd-navbar-collapse-toggle-preset,
            $rd-navbar-min-line-height,
            $rd-navbar-min-font-size,
            $rd-navbar-panel-color
    );
    display: none;
  }
}

// RD Navbar States
.rd-navbar--has-sidebar {
  body {
    padding-left: 270px;
  }
}

.rd-navbar--is-stuck {
  border-bottom: $rd-navbar-border;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}

@import "rd-navbar_includes/_rd-navbar-components.scss";

/*
* @subsection   Hybrid  Styles
*/

//=========    Static & Fullwidth & Sidebar    ===========
.rd-navbar-static, .rd-navbar-sidebar, .rd-navbar-fullwidth {
  // RD Navbar Search
  .rd-navbar-search {
    .form-group {
      label {
        top: 52%;
        transform: translateY(-50%);
        line-height: 1;
      }
    }
    .rd-search {
      input {
        width: 100%;
        padding: 9px 48px 9px 17px;
        font-size: 15px;
      }

      &-submit {
        right: 4px;
      }
    }
  }
}

//=============    Static & Fullwidth     ================
.rd-navbar-static,
.rd-navbar-fullwidth {

  &:after {
    content: '';
    background: $rd-navbar-background;
  }

  .rd-navbar-brand,
  .rd-navbar-nav > li > a,
  .rd-navbar-search-toggle {
    position: relative;
    z-index: 2;
  }

  // RD Navbar Submenu
  .rd-navbar-nav {
    > li {
      > a {
        position: relative;
        display: inline;
        font-size: 15px;
        font-weight: 900;
        line-height: 1.2;
        color: $curious-blue;
        background: transparent;
      }

      &.focus > a,
      &.opened > a,
      > a:hover {
        color: $picton-blue;
        background: $rd-navbar-nav-hover-background;
      }

      &.active > a {
        color: $picton-blue;
        background: $rd-navbar-nav-active-background;
      }
    }

    > .rd-navbar-submenu {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        opacity: 0;
        visibility: hidden;
        @include transform(translateY(30px));
      }

      &.focus {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          visibility: visible;
          @include transform(translateY(0));
        }
      }

      .rd-navbar-submenu.focus > .rd-navbar-dropdown,
      .rd-navbar-submenu.opened > .rd-navbar-dropdown {
        display: block;
      }
    }
  }

  // RD Navbar Inner
  .rd-navbar-inner {
    position: relative;
    max-width: $rd-navbar-width;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    > li > .rd-navbar-dropdown {
      position: absolute;
      left: -14px;
      z-index: 5;
      display: block;
      width: $rd-navbar-dropdown-width;
      margin-top: 18px;
      text-align: left;
      background: $rd-navbar-dropdown-background;

      @media (min-width: $screen-lg-min) {
        left: -21px;
      }
    }

    li.rd-navbar--has-dropdown {
      position: relative;
    }

    > li {
      display: inline-block;

      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        top: 100%;
        z-index: 1;
      }

      > .rd-navbar-dropdown {
        li {
          min-height: 50px;
        }

        .rd-navbar-dropdown {
          position: absolute;
          top: -1px;
          left: 100%;
          z-index: 2;
          opacity: 0;
          visibility: hidden;

          a {
            line-height: 1.6666;
          }
        }
      }
    }

    li.focus,
    li.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
        @include transform(translateY(0));
      }
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    @include display-flex;
    @include flex-wrap(wrap);
    width: $rd-navbar-dropdown-width;
    background: $white;
    border: 1px solid $mercury;
    border-top: 0;

    .rd-navbar-nav-dark & {
      background: $rd-navbar-dropdown-background;
    }

    .rd-navbar-dropdown {
      li {
        padding: 12px 18px 11px;
      }

      > li:first-child {
        padding-top: 13px;
      }

      @include order(3);
    }

    > li {
      padding: 12px 18px 12px;
    }

    > li {
      border-top: 1px solid $mercury;
    }

    > li > a {
      display: inline;
      background: $rd-navbar-dropdown-item-background;
      &, &:focus, &:active {
        color: $rd-navbar-dropdown-item-color;
      }
    }

    > li > a:hover {
      color: $rd-navbar-dropdown-item-hover-color;
      background: $rd-navbar-dropdown-item-hover-background;
    }

    > li.focus > a,
    > li.opened > a {
      color: $rd-navbar-dropdown-item-active-color;
      background: $rd-navbar-dropdown-item-active-background;
    }
  }

  // RD Navbar Megamenu
  .rd-navbar-megamenu {
    display: table;
    table-layout: fixed;
    left: 10px;
    width: 98%;
    position: absolute;
    text-align: left;
    border-spacing: 31px 25px;
    table-layout: fixed;
    margin-top: 10px;
    max-width: $rd-navbar-width;
    background: $rd-navbar-megamenu-background;
    z-index: 4;

    > li {
      position: relative;
      display: table-cell;

      > p {
        font-weight: 400;
        text-transform: uppercase;
        color: $white;
      }

      > ul {
        padding: 8px 9px;

        li + li {
          margin-top: 5px;
        }

        a {
          display: inline-block;
          color: $rd-navbar-megamenu-item-color;
          background: $rd-navbar-megamenu-item-background;

          &:hover {
            color: $rd-navbar-megamenu-item-hover-color;
            background: $rd-navbar-megamenu-item-hover-background;
          }
        }
      }

      & + li {
        padding-left: 10px;
        &:before {
          content: '';
          position: absolute;
          top: $rd-navbar-megamenu-gutter;
          bottom: $rd-navbar-megamenu-gutter;
          width: 1px;
          right: 100%;
          margin-right: 17px;
          background: lighten($rd-navbar-megamenu-background, 7.450980392156865%);
        }
      }
    }
  }

  // RD Navbar Submenu Toggle
  .rd-navbar-submenu-toggle {
    display: inline-block;
    vertical-align: baseline;
    cursor: pointer;
    z-index: 100;
    &::after {
      content: '\f107';
      position: relative;
      display: inline-block;
      z-index: 2;
      font: 400 18px "FontAwesome";
      text-align: center;
      transition: 0.4s all ease;
      color: $curious-blue;
      will-change: transform;
      -webkit-filter: blur(0);
    }

    &:hover {
      color: $picton-blue;
    }

    .rd-navbar-nav-dark & {
      color: $malibu;

      &:hover {
        color: $rd-navbar-nav-hover-color;
      }
    }
  }

  .rd-navbar-nav > li {
    > .rd-navbar-submenu-toggle {
      position: relative;
      top: 1px;
      width: 6px;
      margin-left: 5px;
      text-align: center;
    }

    &.focus > .rd-navbar-submenu-toggle,
    &.opened > .rd-navbar-submenu-toggle,
    & > a:hover + .rd-navbar-submenu-toggle {
      &::after {
        @include transform (rotate(180deg));
        color: $rd-navbar-nav-hover-color;
      }
    }
  }

  .rd-navbar-dropdown {
    .rd-navbar-submenu-toggle {
      display: inline-block;
      vertical-align: middle;
      margin-left: 13px;

      &::after {
        top: 1px;
      }
    }

    > li.focus > .rd-navbar-submenu-toggle,
    > li.opened > .rd-navbar-submenu-toggle,
    > li > a:hover + .rd-navbar-submenu-toggle {
      &::after {
        @include transform (rotate(-90deg));
        color: $rd-navbar-dropdown-item-hover-color;
      }
    }
  }

  // RD Navbar Clone
  &.rd-navbar--is-clone {
    display: none;

    &.rd-navbar--is-stuck {
      display: block;
    }
  }

  // RD Navbar Search
  .rd-navbar-search {
    position: relative;
    transition: .33s all ease;

    .rd-search {
      text-align: right;
      border: 1px solid $gray-lighter;
      border-radius: 5px;

      &:after {
        left: 100%;
      }
    }

    .rd-search,
    &-toggle {
      will-change: visibility, opacity;
    }

    &-toggle {
      position: absolute;
      right: 11px;
      top: 50%;
      @include transform(translateY(-50%));
      color: $bright-gray;
      background-color: transparent;
      border: none;

      &:focus {
        outline: 0;
      }

      &:hover {
        color: $brand-primary;
      }
    }

    input {
      border-radius: 5px;
      color: $gray-chateau;
      background: $white;
      transition: .33s all ease;
    }

    .form-group {
      position: relative;
      width: 100%;
      margin: 0;
      text-align: left;
      transition: .33s all ease;

      .form-label {
        transition: .33s all ease;
      }
    }

    &.active {
      .rd-search {
        opacity: 1;
        visibility: visible;
      }
    }

    .rd-navbar-search-toggle,
    .rd-search-submit {
      color: $bright-gray;

      &:hover {
        color: $curious-blue;
      }
    }

    &.rd-navbar-search-opened {
      @media (max-width: $screen-md-max) {
        .rd-search {
          top: calc(100% + 22px);
        }
      }
      @media (min-width: $screen-lg-min) {
        .rd-navbar-search-toggle {
          display: none;
        }
      }
    }

    @media (max-width: $screen-md-max) {
      position: static;
      .rd-search {
        position: absolute;
        top: 100%;
        right: -4px;
        width: calc(100vw - 23px);
        padding: 7px;
        opacity: 0;
        visibility: hidden;
        transition: .33s all ease;

        label {
          width: 100%;
          padding: 9px 4px;
        }

        &:before {
          content: '';
          position: absolute;
          top: -1px;
          bottom: -1px;
          left: 50%;
          z-index: -1;
          @include transform(translateX(-50%));
          width: 120vw;
          background: $aqua-haze;
        }
      }
    }

    @media (min-width: $screen-lg-min) {
      .rd-navbar-search-toggle {
        visibility: visible;
        opacity: 1;
        &:after {
          display: none;
        }

      }
    }
  }

  .rd-search {
    opacity: 0;
    visibility: hidden;
    transition: .33s all ease;
  }

  .rd-navbar-search-opened {
    @media (min-width: $screen-lg-min) {
      .rd-search {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .rd-navbar-search:not(.rd-navbar-search-opened) {

    @media (min-width: $screen-lg-min) {
      .rd-search {
        width: 0px;
        margin-left: auto;
        overflow: hidden;

        label {
          transition: none;
        }
      }
      &:not(.active) {
        .rd-search {
          label {
            color: transparent;
          }
        }
      }
      &.active {
        .rd-search {
          width: 100%;
          opacity: 1;
          visibility: visible;
          overflow: visible;
        }
        .rd-navbar-search-toggle {
          &:before { @include transform(none); }
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
  &.rd-navbar--is-stuck .rd-navbar-nav-dark {
    &:before {
      border-bottom: 1px solid $charade;
    }
  }

  .rd-navbar-nav-dark {

    .rd-navbar-search {
      .rd-search {
        border-color: $bright-gray;

        &:before {
          background: $ebony-clay;
        }
      }
      input {
        background: $bright-gray;
        border: 1px solid $bright-gray;
      }

      .rd-navbar-search-toggle,
      .rd-search-submit {
        color: $white;

        &:hover {
          color: $curious-blue;
        }
      }
    }

    .rd-search-results-live {
      @extend .rd-search-results-live-dark;
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .rd-navbar-nav {
      > li {
        > a {
          color: $rd-navbar-nav-color;
        }

        &.focus > a,
        &.opened > a,
        > a:hover {
          color: $rd-navbar-nav-hover-color;
          background: $rd-navbar-nav-hover-background;
        }

        &.active > a {
          color: $rd-navbar-nav-active-color;
          background: $rd-navbar-nav-active-background;
        }
      }
    }
  }

  // RD Navbar Stuck
  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    background: $rd-navbar-background;

    .rd-navbar-panel,
    .rd-navbar-aside {
      display: none;
      transition: none;
    }
  }

  .rd-navbar-megamenu {
    @extend %rd-navbar-transition;
    position: absolute;
    @include transform(translateY(30px));
    padding: 8px 0;
    text-align: left;
    visibility: hidden;
    opacity: 0;
  }

  // RD Navbar States
  .rd-navbar--has-dropdown {
    position: relative;
  }

  &.rd-navbar--is-clone {
  }
}

//=============      Fixed & Sidebar      ================
.rd-navbar-fixed,
.rd-navbar-sidebar {
  // RD Navbar Collapse
  .rd-navbar-collapse-toggle {
    display: inline-block;
    z-index: 9999;
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    display: block;
  }

  .rd-navbar-collapse-items {
    position: absolute;
    width: $rd-navbar-nav-min-width - 10px;
    padding: 25px 15px;
    box-shadow: $rd-navbar-shadow;
    color: $rd-navbar-panel-color;
    background: $rd-navbar-panel-background;
    font-size: 16px;
    line-height: 34px;

    li > * {
      vertical-align: middle;
    }

    li + li {
      margin-top: 10px;
    }

    .icon,
    a {
      display: inline-block;
      font-size: 16px;
      line-height: 30px;
    }

    .icon, a[class*="fa"]:before {
      display: inline-block;
      width: 30px;
      height: 30px;
      padding-right: 5px;
    }
  }
}

.rd-navbar-fixed .rd-navbar-nav,
.rd-navbar-sidebar {
  width: $rd-navbar-nav-min-width;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  line-height: 34px;
  color: $rd-navbar-color;
  background: $rd-navbar-fixed-panel-background;
  z-index: 998;
  padding: 10px 0;

  &:before,
  &:after {
    content: '';
    display: block;
    height: $rd-navbar-min-height;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($rd-navbar-background, 10%);
    border: none;
    border-radius: 0;
    opacity: .2;
  }

  &::-webkit-scrollbar-track {
    background: $rd-navbar-background;
    border: none;
    border-radius: 0;
  }
}

.rd-navbar-fixed .rd-navbar-nav,
.rd-navbar-sidebar .rd-navbar-nav {
  li {
    position: relative;

    li > a {
      padding-left: 20px;
    }

    > a {
      position: relative;
      display: block;
      padding: 5px 45px 5px 15px;
    }

    &.active > a,
    &.focus > a,
    > a:hover {
      color: $rd-navbar-fixed-panel-item-hover-color;
      background: $rd-navbar-fixed-panel-item-hover-background;
    }

    & + li, > img + a, > a + a, > a + ul {
      margin-top: ($rd-navbar-min-height - $rd-navbar-min-line-height) / 2;
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    > li > a {
      padding-left: 20px;
    }

    ul > li > a {
      padding-left: 25px;
    }
  }
}

.rd-navbar-fixed,
.rd-navbar-sidebar {
  // RD Navbar Search
  .rd-navbar-search {
    .rd-search {
      &-submit {
        @include transform-origin(50% 50%);
        @include scale(.7);
      }
    }
  }
}

@import "rd-navbar_includes/_rd-navbar-static.scss";
@import "rd-navbar_includes/_rd-navbar-fullwidth.scss";
@import "rd-navbar_includes/_rd-navbar-fixed.scss";
@import "rd-navbar_includes/_rd-navbar-sidebar.scss";

/*
* @subsection   Page boxed layout style redeclaration
*
* @description  Redefines navbar style inside boxed layout
*
* @see          ../modules/_page-layouts.scss
*/
html.boxed {
  &.rd-navbar--has-sidebar {
    body {
      padding-left: $rd-navbar-nav-min-width + 30px;
      padding-right: 30px;
    }
  }

  .rd-navbar--is-clone {
    max-width: $layout-boxed-width;
    margin-left: auto;
    margin-right: auto;
  }
}
