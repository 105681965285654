//
// Responsive unit
// --------------------------------------------------

@include unit-responsive($medias, 20px, 30px);

// Unit Spacing
//

.unit-spacing-xxs {
  @include unit-spacing($medias, 4px);
}

.unit-spacing-xs {
  @include unit-spacing($medias, 7px);
}

.unit-spacing-sm {
  @include unit-spacing($medias, 14px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 7px, 20px);
}

.unit-spacing-lg {
  @include unit-spacing($medias, 27px);
}

.unit-spacing-blog {
  @include unit-spacing($medias, 20px);
}