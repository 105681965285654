/*
* @subsection   RD Audio
* @description  Describes style declarations for RD Audio extension
* @author       Rafael Shayvolodyan
* @link         https://ua.linkedin.com/in/rafael-shayvolodyan-3a297b96
* @version      1.0.0
*/

.rd-audio {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $gray-light;
  padding: 10px 15px 5px;
}

.rd-audio.state-loading .rd-audio-progress-bar {
  background: $gray-darker;
}

.rd-audio-progress-bar {
  position: relative;
  width: 100%;
  background: $gray-base;
  height: 4px;
  cursor: pointer;
}

.rd-audio-progress-bar .current {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: $white;
  z-index: 0;
}

.rd-audio-progress-bar-slider {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -3px;
  margin-left: -5px;
  background: $white;
  z-index: 1;
}

.rd-audio.state-playing .rd-audio-play-pause:before {
  content: '\f04c';
  font-family: "FontAwesome";
}

.rd-audio.muted .rd-audio-volume:before {
  content: '\f04c';
  font-family: "FontAwesome";
}

.rd-audio-current-time, .rd-audio-duration {
  font-size: 11px;
  color: $white;
  margin-top: 3px;
}

.rd-audio-current-time {
  float: left;
  margin-left: -5px;
}

.rd-audio-duration {
  float: right;
  margin-right: -5px;
}

.rd-audio-volume-bar {
  position: relative;
  background: $gray-base;
  cursor: pointer;
}

.rd-audio-volume-bar .current {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: $white;
  z-index: 0;
}

.rd-audio-volume-bar-slider {
  position: absolute;
  background: $white;
  z-index: 1;
}

.rd-audio-volume-bar-vertical {
  width: 10px;
  height: 100px;
}

.rd-audio-volume-bar-vertical .rd-audio-volume-bar-slider {
  left: -5px;
  right: -5px;
  height: 4px;
  bottom: 0;
  margin-top: 2px;
}

.rd-audio-volume-bar-horizontal {
  width: 100%;
  height: 4px;
}

.rd-audio-volume-bar-horizontal .rd-audio-volume-bar-slider {
  width: 10px;
  height: 10px;
  top: -3px;
  margin-left: -5px;
}

.rd-audio-title {
  display: block;
  text-align: center;
  text-transform: capitalize;
  color: $white;
  font-size: 12px;
  margin-top: 5px;
  letter-spacing: 1px;
}

.rd-audio-volume {
  display: inline-block;
  vertical-align: middle;
}

.rd-audio-volume-bar {
  display: inline-block;
  width: calc(100% - 35px);
  vertical-align: middle;
  margin-left: 5px;
}

.control-icon {
  font-size: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  color: $white;
}

.control-icon:hover {
  color: $brand-primary;
}
