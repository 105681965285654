/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
  position: absolute;
  text-align: center;
  font-family: $font-family-sec;
  top: 50% !important;
  transform: translateY(-63%) !important;
  -webkit-transform: translateY(-63%) !important;
}

@media (min-width: $screen-lg) {
  #DateCountdown {
    width: 100%;
  }
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 15px !important;
  font-weight: 400;
  top: 16vw;

  & + * {
    margin-top: 0;
  }

  @media (min-width: $screen-xs-min) {
    top: 75px;
  }
  
  @media (min-width: $screen-sm-min) {
    top: 94px;
  }
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
  display: block;
  font-family: $font-family-base;
  font-size: 30px;
  text-align: center;
  font-weight: 900;

  @media (min-width: $screen-sm) {
    font-size: 36px !important;
  }

  @media (min-width: $screen-lg) {
    font-size: 48px !important;
  }
}

.DateCountdown-1 {
  .time_circles > div > h4 {
    font-weight: 900;
    @media (min-width: $screen-sm-min) {
      font-size: 18px !important;
    }
  }
}

