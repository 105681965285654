//
// Custom utilities
// --------------------------------------------------

// Helpers
// -------------------------
.relative {
  position: relative;
}

.block-top-level {
  position: relative;
  z-index: 3;
}

.noshrink {
  @include flex-shrink(0);
}

.height-fill {
  @include display-flex;
  @include flex-direction(column);
  @include align-items(stretch);

  > * {
    @include flex-grow(1);
  }
}

.centered {
  @include display-flex;
  @include justify-content(center);
  @include align-items(center);

  > * {
    width: 100%;
  }
}

.border-radius-large {
  border-radius: 17px;
}

// Main Layout
// -------------------------
* + h1,
* + .h1 {
  margin-top: 10px;
}

* + h2,
* + .h2 {
  margin-top: 15px;
}

* + h3,
* + .h3 {
  margin-top: 20px;
}

* + h4,
* + .h4 {
  margin-top: 25px;
}

* + h5,
* + .h5 {
  margin-top: 20px;
}

* + h6,
* + .h6 {
  margin-top: 15px;
}

* + p {
  margin-top: 18px;
}

* + hr {
  margin-top: 18px;
}

hr + * {
  margin-top: 18px;
  @media (min-width: $screen-lg-min) {
    margin-top: 30px;
  }
}

p + p {
  margin-top: 12px;
}

hr + section {
  margin-top: 0;
}

* + .btn {
  margin-top: 29px;
}

* + .form-calculator {
  margin-top: 40px;

  @media (min-width: $screen-sm-min) {
    margin-top: 50px;
  }
}

* + h6,
* + .h6 {
  margin-top: 30px;

  @media (min-width: $screen-lg-min) {
    margin-top: 45px;
  }
}

h1 + *,
.h1 + * {
  margin-top: 14px;
}

h3 + *,
.h3 + * {
  margin-top: 30px;
}

h6 + *,
.h6 + * {
  margin-top: 20px;
}

h5 + *,
.h5 + * {
  margin-top: 16px;
}

* + .link {
  margin-top: 18px;
}

* + .contact-info {
  margin-top: 31px;
}

* + .list-inline {
  margin-top: 32px;
}

* + .list-terms {
  margin-top: 42px;

  @media (min-width: $screen-lg-min) {
    margin-top: 62px;
  }
}

* + .list-progress-bars {
  margin-top: 19px;
}

* + .list-marked-bordered {
  margin-top: 12px;
}

* + .list-post-preview {
  margin-top: 27px;
}

* + .tags-cloud {
  margin-top: 27px;
}

* + h3,
* + .h3 {
  margin-top: 12px;
}

.elements-group-1 {
  white-space: nowrap;
  > * {
    display: inline-block;
    margin: 0;
  }

  > *:last-child {
    margin-left: 30px;
  }
}

// Misc
// -------------------------
.contact-info {
  vertical-align: baseline;

  a {
    display: inline-block;

    span {
      color: inherit;
    }
  }

  dl {
    dt, dd {
      display: inline-block;
    }

    dt {
      @media (min-width: $screen-sm-min) {
        padding-right: 8px;
      }
    }
    dt:after {
      content: ':';
      display: inline-block;
      text-align: center;
    }
  }

  .dl-inline {
    dt {
      padding-right: 0;
    }
  }
}

.contact-info-list {
  li + li {
    margin-top: 14px;
  }

  .unit span {
    display: block;
    text-align: left;
  }
}

.elements-group {
  > * + * {
    margin-top: 30px;
  }
  @media (min-width: $screen-md-min) {
    @include justify-content(space-between);
    &, > * {
      @include display-flex;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-items(center);
    }

    * + * {
      margin-top: 0;
    }

    > * + * {
      margin-top: 0;
      margin-left: 15px;
    }
  }
}

.element-wrap {
  @media (min-width: $screen-md-min) {
    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.block-wrap {
  display: block;

  span {
    display: block;
    transition: .33s all ease;
    & + span {
      margin-top: -2px;
    }
  }
}

.text-404 {
  font-size: 120px;
  line-height: 1;
  color: $white;
  font-weight: 400;
  font-family: $font-family-sec;

  small {
    display: block;
    margin-top: -.3em;
    font-size: .65em;
    line-height: 1;
  }

  @media (min-width: $screen-sm-min) {
    font-size: 160px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 220px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 311px;
  }
}

.postfix-sm-70 {
  @media (min-width: $screen-sm-min) {
    margin-right: 70px;
  }
}

.hr-wrap {
  @media (min-width: $screen-sm-min) {
    margin-left: 34px;
    margin-right: 17px;
  }
}

.max-width-300 {
  max-width: 300px;
}

.text-style-1 {
  p {
    line-height: (26 / 15);
  }

  * + p {
    margin-top: 18px;
  }

  p + p {
    margin-top: 14px;
  }
}

.border-radius-5 {
  border-radius: 5px;
}

// Shift
.relative-shift-up-5 {
  position: relative;
  top: -5px;
}

.child-offset-41 {
  > * + * {
    margin-top: 41px;
  }
}

.shift-up-36 {
  margin-top: -36px;
}

@media (min-width: $screen-sm-min) {
  .shift-up-sm-0 {
    margin-top: 0;
  }

  .shift-up-sm-5 {
    margin-top: -5px;
  }

  .shift-up-sm-10 {
    margin-top: -10px;
  }
}

.grid-system {
  @media (max-width: $screen-md-max) {
    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.page a.text-gray-base,
.page .text-gray-base a {
  &, &:active, &:focus {
    color: $gray-base;
  }

  &:hover {
    &, * {
      color: $curious-blue;
    }
  }
}