//
// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------

$insets: (14px, 25px, 35px, 40px, 45px, 50px, 55px, 60px, 68px, 75px, 85px, 100px, 125px, 152px, 235px);

@include indent-responsive(section, '', (padding-top, padding-bottom), $medias, $insets);
@include indent-responsive(section, top, padding-top, $medias, $insets);
@include indent-responsive(section, bottom, padding-bottom, $medias, $insets);