//
// Offsets
// --------------------------------------------------


// Insets
// -------------------------

$insets: (0, 10px, 15px, 25px, 30px, 50px, 70px, 100px, 120px);

@include indent-responsive(inset, left, padding-left, $medias, $insets);
@include indent-responsive(inset, right, padding-right, $medias, $insets);


// Elements
// -------------------------


// Grid
// -------------------------


// Sections
// -------------------------


// Responsive
// -------------------------

$offsets: (0, 5px, 10px, 15px, 19px, 22px, 25px, 30px, 34px, 38px, 41px, 45px, 50px, 56px, 60px);

html .page{
  @include indent-responsive(offset, top, margin-top, $medias, $offsets);
}
