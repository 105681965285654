/*
* @subsection   Easy Responsive Tabs
*
* @description  Describes style declarations for Easy Responsive Tabs extension
*
* @author       Samson Onna
* @link         samson3d@gmail.com
* @version      1.0.0
*/

$tabs-btn-height: 40px;
$tabs-btn-indent: 0;

$tabs-btn-color: $gray-base;
$tabs-btn-background: $white;

$tabs-btn-active-color: $white;
$tabs-btn-active-background: $curious-blue;

$tabs-btn-border-radius: 0;
$tabs-btn-border: 1px solid $mercury-1;

$tabs-btn-padding: 11px 20px;

$tab-padding: 20px;

.resp-tabs-list {
  li {
    font-weight: 300;
    cursor: pointer;

    &:hover {
    }
  }

  @media (min-width: $screen-sm-min) {
    font-size: 22px;
  }
}

.resp-accordion {
  display: none;
}

.resp-tab-content {
  display: none;
}

.resp-tab-content-active {
  display: block;
}

.responsive-tabs-default {
  .resp-tabs-list {
    display: none;
    word-spacing: $tabs-btn-indent - .25em;
  }

  .resp-accordion, .resp-tabs-list > li {
    word-spacing: normal;
    color: $gray-base;
    padding: $tabs-btn-padding;
    background: $tabs-btn-background;
    border: $tabs-btn-border;
    border-top-left-radius: $tabs-btn-border-radius;
    border-top-right-radius: $tabs-btn-border-radius;
    text-align: center;
    cursor: pointer;
    transition: none;
    transition: background .33s, color .33s;

    &:hover, &.resp-tab-active {
      font-weight: 400;
      color: $tabs-btn-active-color;
      background: $tabs-btn-active-background;
      border-color: $tabs-btn-active-background;
    }
  }

  .resp-accordion {
    display: block;
    border-radius: 0;
  }

  .resp-tab-content {
    display: none;
    padding: $tab-padding;
    background: $tabs-btn-background;
    color: $gray-base;
    background: $white;
  }

  @media (max-width: $screen-xs-max) {
    .resp-accordion:not(:nth-last-child(2)) {
      border-bottom: 0;
    }
  }
}

.responsive-tabs-variant-2 {
}

.responsive-tabs:not([data-type='accordion']) {
  @media (min-width: 768px) {
    .resp-accordion {
      display: none;
    }

    .resp-tabs-list {
      display: block;
      .resp-tab-active {
        pointer-events: none;
      }
    }
  }
}

.responsive-tabs.responsive-tabs-horizontal {
  .resp-accordion:not(:nth-last-child(2)) {
    border-bottom: 0;
  }
  @media (min-width: $screen-sm-min) {
    .resp-tabs-list {
      @include display-flex;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-items(stretch);

      li {
        position: relative;
        margin: 0;
        z-index: 10;
        @include flex-grow(1);
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;

        &:after {
          display: none;
          content: '';
          position: absolute;
          left: 50%;
          @include transform(translateX(-50%));
          bottom: -7px;
          @include triangle(bottom, 13px, 6px, $tabs-btn-active-background);
          transition: none !important;
        }
      }

      li + li {
        border-left: 0;
      }

      .resp-tab-active {
        &:after {
          display: block;
        }
      }
    }

    .resp-tab-content {
      padding: 30px 0 26px;
    }
  }
}

.responsive-tabs.responsive-tabs-vertical {
  .resp-accordion:not(:nth-last-child(2)) {
    border-bottom: 0;
  }

  @media (min-width: $screen-sm-min) {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(flex-start);

    .resp-tabs-list {
      @include display-flex;
      @include flex-direction(column);
      @include flex-wrap(nowrap);
      @include align-items(stretch);
      min-width: 190px;

      li {
        position: relative;
        z-index: 10;
        width: 100%;
        padding: 11px 20px 12px;
        margin: 0;
        @include flex-grow(1);

        &:after {
          display: none;
          content: '';
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
          right: -7px;
          @include triangle(right, 6px, 13px, $tabs-btn-active-background);
          transition: none !important;
        }
      }

      li + li {
        margin-top: -1px;
      }

      .resp-tab-active {
        &:after {
          display: block;
        }
      }
    }

    .resp-tabs-container {
      @include flex-grow(1);
    }

    .resp-tab-content {
      padding: 0 30px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .resp-tab-content {
      padding: 0 50px;
      margin-top: -5px;
    }
  }
}

.responsive-tabs[data-type='accordion'] {
  border-bottom: 1px solid $mercury-1;;
  .resp-arrow {
    position: absolute;
    top: 31%;
    right: 40px;
    display: inline-block;
    transition: .33s;
    will-change: transform;
    &:before {
      content: '\e00d';
      font-family: 'fl-budicons-free';
      font-size: 24px;
      line-height: 1;
      color: $polo-blue;
    }
  }

  .resp-accordion {
    position: relative;
    display: block;

    cursor: pointer;

    @media (min-width: $screen-sm-min) {
      font-size: 16px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 18px;
    }
  }

  .resp-accordion {
    border: 1px solid $mercury-1;
    border-bottom: 0;
    padding: 20px 68px 21px 26px;
  }
  .resp-tab-content {
    border: 1px solid $mercury-1;
    border-top-width: 0;
    padding: 18px 68px 31px 26px;
    border-bottom: 0;
  }

  .resp-tab-active {
    .resp-arrow {
      @include rotate(180deg);
    }
  }
}