/*
* Fixed Layout
*/
.rd-navbar-fixed {
  display: block;

  // RD Navbar Brand
  .rd-navbar-brand {
    position: fixed;
    top: 4px;
    left: 56px;
    z-index: 17;
    display: block;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    > * {
      font-size: 30px;
    }

    .brand-name {
      height: 48px;
      line-height: 46px;
    }

    .brand-slogan {
      display: none;
    }
  }

  // RD Navbar Panel
  .rd-navbar-panel {
    @include display-flex();
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: ($rd-navbar-fixed-height - $rd-navbar-fixed-line-height) / 2;
    height: $rd-navbar-fixed-height;
    color: $rd-navbar-fixed-panel-color;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: $rd-navbar-fixed-shadow;
      border-bottom: $rd-navbar-fixed-border;
      background: $rd-navbar-fixed-panel-background;
    }
  }

  // RD Navbar Toggle
  .rd-navbar-toggle {
    display: inline-block;
  }

  .rd-navbar-nav-wrap {
    @extend %rd-navbar-transition;
    position: fixed;
    top: -56px;
    left: 0;
    width: 270px;
    border-right: $rd-navbar-fixed-border;
    padding: 112px 0 56px;
    bottom: -56px;
    color: $white;
    background: $rd-navbar-background;
    z-index: 15;
    @include transform(translateX(-120%));

    &.active {
      @include transform(translateX(0));
    }

    .elements-group-1 {
      padding: 15px 10px 1px;
      text-align: center;
      .text-white {
        color: $gray-base;
      }

      .icon {
        width: 28px;
        height: 28px;
        font-size: 14px;
        line-height: 30px;
      }

      .icon {
        &, &:active, &:focus {
          color: $gray-dark;
          background: $gray-lighter;
        }

        &:hover {
          color: $white;
          background: $malibu;
        }
      }
    }
  }

  &.rd-navbar-light {
    .block-wrap-list {
      li + li {
        text-align: center;
        margin-left: 0;
      }
    }
  }

  .rd-navbar-nav-wrap-inner {
    height: 100%;
  }

  .rd-navbar-nav-wrap-inner,
  .rd-navbar-nav {
    border-right: $rd-navbar-fixed-border;
  }

  .rd-navbar-nav {
    display: block;
    height: 100%;
    overflow: auto;
    font-size: 16px;
    line-height: 26px;
    text-align: left;

    &:before, &:after {
      content: '';
      display: block;
      height: 8px;
    }

    li {
      > a {
        display: block;
        font-weight: 900;
        padding: 11px 56px 11px 16px;
        color: $rd-navbar-fixed-panel-color;
      }

      &:hover,
      &.focus,
      &.active,
      &.opened {
        > a, > a:hover {
          color: $rd-navbar-fixed-panel-item-hover-color;
          background: $rd-navbar-fixed-panel-item-hover-background;
        }
        > .rd-navbar-submenu-toggle {
          &::after {
            color: $rd-navbar-fixed-submenu-toggle-focus-color;
          }
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
  }

  .rd-navbar-submenu {
    position: relative;
    @extend %rd-navbar-transition;

    li > a {
      padding-left: 32px;
    }

    .rd-navbar-dropdown li li > a,
    .rd-navbar-megamenu ul li li > a {
      padding-left: 48px;
    }

    &.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        display: block;
      }
    }
  }

  // RD Navbar Search
  .rd-navbar-search {
    display: block;

    .rd-search {
      border: 0;
    }

    &-toggle {
      display: block;
      width: 48px;
      height: 48px;
      z-index: 18;
      color: $rd-navbar-color;
    }

    .rd-navbar-live-search-results {
      top: $rd-navbar-fixed-height;
    }

    .rd-search {
      position: relative;
      margin-top: 7px;
      padding: 6px 12px;
      height: $rd-navbar-fixed-height;

      .form-group {
        label {
          top: 23px;
        }
        input {
          width: 100%;
          padding: 11px 38px 12px 17px;
          margin: 0;
          color: $bright-gray;
          background: $white;
          border: 1px solid $gray-lighter;
        }
      }

      &-submit {
        position: absolute;
        top: 3px;
        right: 10px;
        border: 0;
        background: none;
        color: $bright-gray;

        &:focus {
          outline: none;
        }

      }
    }

    &-toggle {
      display: none;
    }
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    li {
      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        transition: opacity 0.3s, height 0.4s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
      }

      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          height: auto;
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-fixed-submenu-toggle-focus-color;
          &::after {
            @include transform(rotate(180deg));
          }
        }
      }

    }

    .rd-navbar-submenu-toggle {
      &::after {
        content: '\f078';
        position: absolute;
        top: 22px;
        right: 0;
        margin-top: -22px;
        width: 65px;
        height: 44px;
        font: 400 14px "FontAwesome";
        line-height: 42px;
        text-align: center;
        transition: 0.4s all ease;
        z-index: 2;
        cursor: pointer;
        color: $rd-navbar-fixed-submenu-toggle-color;
      }
    }
  }

  // Hybrid Styles
  .rd-navbar-collapse,
  .rd-navbar-search-toggle {
    position: fixed;
    top: ($rd-navbar-fixed-height - $rd-navbar-fixed-line-height) / 2;
    height: $rd-navbar-fixed-line-height;
    z-index: 1000;
    background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }
  }

  // RD Navbar aside
  .rd-navbar-aside {
    &, .rd-navbar-aside-toggle {
      position: fixed;
      z-index: 1000;
      display: block;
      height: $rd-navbar-min-line-height;
    }

    top: 0;
    right: 0;
    width: 100%;

    &.active {
      .rd-navbar-aside-content {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .rd-navbar-aside-toggle {
    top: ($rd-navbar-fixed-height - $rd-navbar-min-line-height) / 2;
    right: ($rd-navbar-fixed-height - $rd-navbar-min-line-height) / 2;
    width: $rd-navbar-min-line-height;
    @include make-toggle(
            'collapse-preset-1',
            $rd-navbar-min-line-height,
            $rd-navbar-min-font-size,
            $rd-navbar-fixed-toggle-color
    );
  }

  .rd-navbar-aside-content {
    position: absolute;
    overflow: hidden;
    top: calc(100% + 7px);
    right: 0;
    width: calc(100% + 2px);
    margin: 0 -1px;
    text-align: center;
    pointer-events: auto;
    background: $white;
    border: $rd-navbar-fixed-border;
    opacity: 0;
    visibility: hidden;
    transition: .23s all ease-out;

    .block-wrap-list {
      padding: 20px 30px 20px 5px;
      li {
        margin-top: 15px;
        margin-left: 25px;
      }
    }

    .block-wrap {
      display: inline-block;
      text-align: center;
    }

    @media (min-width: $screen-sm-min) {
      text-align: center;

      .block-wrap-list {
        li {
          text-align: left;
        }
      }
    }

    .block-container {
      padding: 16px;
    }
  }

  &.rd-navbar-light {
    .rd-navbar-aside-content {
      .block-wrap-list {
        li + li {
          margin-top: 25px;
        }
      }

      .block-right {
        margin-top: 30px;
      }

      .block-wrap {
        display: block;
      }

      @media (min-width: $screen-sm-min) {
        width: auto;
        .block-wrap-list {
          li + li {
            margin-top: 15px;
            margin-left: 0;
          }
        }

        .block-right {
          margin-top: 10px;
        }
      }
    }
  }

  // RD Navbar States
  &.active {
    .rd-navbar-nav {
      @include transform(translateX(0));
    }
  }

  &.rd-navbar--on-search {
    @media (max-width: 1199px) {
      .brand-name {
        opacity: 0;
        visibility: hidden;
        @include transform(scale(0.7));
      }
    }
  }

  &.rd-navbar--is-clone {
    display: none;

    .rd-navbar-panel {
      @include transform(translateY(-110%));
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-panel {
        @include transform(translateY(0));
      }
    }
  }

  .rd-navbar-fixed--visible {
    display: block;
  }

  .rd-navbar-fixed--hidden {
    display: none;
  }
}

html.rd-navbar-fixed-linked {
  .page {
    padding-top: 56px;
  }
}