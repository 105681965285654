//
// Product utilities
// --------------------------------------------------
.product {
  position: relative;
  padding: 24px 31px;
  text-align: center;
  transition: .22s;


  #{headings()} {
    color: $gray-base;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    @include box-shadow(0 0 5px 0 rgba(#010304, .13));
    opacity: 0;
    transition: .5s all ease;
  }


  &:hover {
    &:before {
      opacity: 1;
    }
    .icon-decoration-border {
      &:before,
      &:after {
        left: 5px;
      }

      &-inner:before,
      &-inner:after {
        right: 5px;
      }

      &:before,
      &-inner:before {
        top: 5px;
      }

      &:after,
      &-inner:after {
        bottom: 5px;
      }
    }
  }

  .fl-sympletts-coffee2 {
    left: 54%;
  }
}

@media (min-width: $screen-sm-min) {
  .product-inset-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}